<div class="manage-news-container" *transloco="let t">
    <h1>{{ t('news.manage.title') }}</h1>

    <ng-container *ngIf="!loading; else loadingTemplate">
        <ng-container *ngIf="masjidsWithNews.length > 0; else noMasjidsTemplate">
            <div class="masjids-list">
                <div *ngFor="let masjid of masjidsWithNews" class="masjid-section">
                    <mat-card>
                        <mat-card-header>
                            <mat-card-title>{{ masjid.name }}</mat-card-title>
                        </mat-card-header>

                        <mat-card-content>
                            <div class="masjid-actions">
                                <button mat-raised-button color="primary" [routerLink]="['/news/create', masjid.id]">
                                    <mat-icon>add</mat-icon>
                                    {{ t('news.manage.createNews') }}
                                </button>
                            </div>

                            <mat-divider class="section-divider"></mat-divider>

                            <ng-container *ngIf="masjid.news && masjid.news.length > 0; else noNewsTemplate">
                                <div class="news-list">
                                    <mat-card *ngFor="let news of masjid.news" class="news-item">
                                        <mat-card-header>
                                            <mat-card-title>{{ news.title }}</mat-card-title>
                                            <mat-card-subtitle>
                                                {{ news.created_date?.toJSDate() | date:'medium' }}
                                            </mat-card-subtitle>
                                        </mat-card-header>

                                        <mat-card-content>
                                            <p>{{ news.description }}</p>
                                        </mat-card-content>

                                        <mat-card-actions>
                                            <button mat-button color="primary" [routerLink]="['/news/edit', news.news_id]" *ngIf="news.news_id">
                                                <mat-icon>edit</mat-icon>
                                                {{ t('common.edit') }}
                                            </button>
                                            <button mat-button color="warn" (click)="onDeleteNews(news.news_id!, masjid.id)" *ngIf="news.news_id">
                                                <mat-icon>delete</mat-icon>
                                                {{ t('common.delete') }}
                                            </button>
                                        </mat-card-actions>
                                    </mat-card>
                                </div>
                            </ng-container>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </ng-container>
    </ng-container>

    <!-- Loading Template -->
    <ng-template #loadingTemplate>
        <div class="loading-state">
            <mat-spinner></mat-spinner>
        </div>
    </ng-template>

    <!-- No Masjids Template -->
    <ng-template #noMasjidsTemplate>
        <div class="empty-state">
            <mat-icon class="empty-icon">mosque</mat-icon>
            <p class="empty-message">{{ t('news.manage.noMasjids') }}</p>
        </div>
    </ng-template>

    <!-- No News Template -->
    <ng-template #noNewsTemplate>
        <div class="empty-state">
            <mat-icon class="empty-icon">newspaper</mat-icon>
            <p class="empty-message">{{ t('news.manage.noNews') }}</p>
        </div>
    </ng-template>
</div> 