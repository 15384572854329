import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { MasjidDataInterface } from 'src/app/masjids/interfaces/masjidData.interface';
import { loadAdminMasjids } from 'src/app/masjids/store/actions/masjid.actions';
import { userAdminMasjidListSelector } from 'src/app/masjids/store/selectors';
import { NewsInterface } from '../../interfaces/news.interface';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { switchMap, map, forkJoin } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NewsService } from 'src/app/shared/services/news.service';

interface MasjidWithNews extends MasjidDataInterface {
    news: NewsInterface[];
}

@UntilDestroy()
@Component({
    selector: 'app-manage-news',
    templateUrl: './manage-news.component.html',
    styleUrls: ['./manage-news.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatCardModule,
        MatButtonModule,
        MatIconModule,
        MatDividerModule,
        MatProgressSpinnerModule,
        RouterModule,
        TranslocoModule,
        MatDialogModule
    ],
})
export class ManageNewsComponent implements OnInit {
    masjidsWithNews: MasjidWithNews[] = [];
    loading = true;

    constructor(
        private store: Store,
        private newsService: NewsService,
        private notification: NotificationService,
        private transloco: TranslocoService,
        private dialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.loadMasjidsWithNews();
    }

    private loadMasjidsWithNews(): void {
        this.store
            .select(userAdminMasjidListSelector)
            .pipe(
                switchMap((masjids: MasjidDataInterface[]) => {
                    if (!masjids?.length) {
                        this.loading = false;
                        return of([]);
                    }

                    const newsRequests = masjids.map((masjid) =>
                        this.newsService.getLatestNews(masjid.id).pipe(
                            map((news) => ({
                                ...masjid,
                                news: news.sort((a: NewsInterface, b: NewsInterface) => 
                                    b.created_date!.toMillis() - a.created_date!.toMillis()
                                )
                            }))
                        )
                    );

                    return forkJoin(newsRequests);
                }),
                untilDestroyed(this)
            )
            .subscribe({
                next: (masjidsWithNews: MasjidWithNews[]) => {
                    this.masjidsWithNews = masjidsWithNews;
                    this.loading = false;
                },
                error: (error) => {
                    console.error('Error loading masjids with news:', error);
                    this.notification.notifyFailure('news.manage.errors.loadFailed');
                    this.loading = false;
                },
            });
    }

    onDeleteNews(newsId: string, masjidId: string): void {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '400px',
            data: {
                title: this.transloco.translate('news.manage.deleteNews'),
                message: this.transloco.translate('news.manage.confirmDelete'),
                confirmText: this.transloco.translate('common.yes'),
                cancelText: this.transloco.translate('common.no')
            }
        });

        dialogRef.afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe(result => {
                if (result) {
                    this.notification.loading('news.create.loading.deletingNews');
                    this.newsService.deleteNews(newsId)
                        .pipe(untilDestroyed(this))
                        .subscribe({
                            next: () => {
                                this.notification.notifySuccess('news.create.success.newsDeleted');
                                // Update the local state
                                const masjidIndex = this.masjidsWithNews.findIndex(m => m.id === masjidId);
                                if (masjidIndex > -1) {
                                    this.masjidsWithNews[masjidIndex].news = 
                                        this.masjidsWithNews[masjidIndex].news.filter(n => n.news_id !== newsId);
                                }
                                this.notification.removeLoading();
                            },
                            error: (error) => {
                                console.error('Error deleting news:', error);
                                this.notification.notifyFailure('news.create.errors.deleteFailed');
                                this.notification.removeLoading();
                            },
                        });
                }
            });
    }
} 