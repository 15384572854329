import { Injectable } from '@angular/core'
import { ComponentStore } from '@ngrx/component-store'
import { NewsInterface } from 'src/app/news/interfaces/news.interface'
import {
    NewsStateInterface,
    initialNewsState,
} from 'src/app/news/interfaces/news.state.interface'

@Injectable({ providedIn: 'root' })
export class NewsStore extends ComponentStore<NewsStateInterface> {
    constructor() {
        super(initialNewsState)
    }

    // State updaters
    public readonly setNewsList = this.updater(
        (state, news: NewsInterface[]) => ({
            ...state,
            newsList: news,
        })
    )

    // State selectors
    readonly getNewsList = this.select((state) => state.newsList)
}
