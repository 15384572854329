import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable, of, tap } from 'rxjs'
import { loadMasjidsNews } from 'src/app/news/store/actions/news.actions'
import { NewsInterface } from '../../interfaces/news.interface'
import { NewsStore } from '../../store/states/news.state'
import { CommonModule } from '@angular/common'
import { NewsCardComponent } from './news-card/news-card.component'
import { ActivatedRoute } from '@angular/router'
import { NewsService } from 'src/app/shared/services/news.service'
import { NotificationService } from 'src/app/shared/services/notification.service'
import { TranslocoModule, TranslocoService } from '@ngneat/transloco'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatIconModule } from '@angular/material/icon'

@Component({
    selector: 'app-list-news',
    templateUrl: './list-news.component.html',
    styleUrl: './list-news.component.scss',
    standalone: true,
    imports: [
        CommonModule,
        NewsCardComponent,
        TranslocoModule,
        MatIconModule,
        MatProgressSpinnerModule,
    ],
})
export class ListNewsComponent implements OnInit {
    newsList: Observable<NewsInterface[]> = of([])

    constructor(
        private _store: Store,
        private _newsStore: NewsStore,
        private _route: ActivatedRoute,
        private _newsService: NewsService,
        private _notificationService: NotificationService,
        private _transloco: TranslocoService
    ) {}

    ngOnInit(): void {
        console.log('init list news component')
        const masjidId = this._route.snapshot.paramMap.get('masjidId')

        if (masjidId) {
            // If masjidId is provided, load news for that specific masjid
            this.newsList = this._newsService.getLatestNews(masjidId).pipe(
                tap(() => {
                    console.log('news loaded for masjid', masjidId)
                })
            )
        } else {
            // Load all news
            this._store.dispatch(loadMasjidsNews())
            this.newsList = this._newsStore.getNewsList.pipe(
                tap(() => {
                    console.log('all news loaded')
                })
            )
        }
    }
}
