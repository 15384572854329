import { Injectable } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'
import { ComponentStore } from '@ngrx/component-store'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { PhotoOperation } from 'src/app/masjids/interfaces/masjid.state.interface'

export interface EditNewsStateInterface {
    newsData: { [key: string]: any }
    isSubmittingSave: boolean
    deleteFiles: Observable<PhotoOperation>[]
    uploadFiles: Observable<PhotoOperation>[]
}

export const initialEditNewsState: EditNewsStateInterface = {
    newsData: {},
    isSubmittingSave: false,
    deleteFiles: [],
    uploadFiles: []
}

@UntilDestroy({ checkProperties: true })
@Injectable({ providedIn: 'root' })
export class EditNewsStore extends ComponentStore<EditNewsStateInterface> {
    constructor(private _store: Store) {
        super(initialEditNewsState)
    }

    readonly getNewsData = this.select((state) => state.newsData)
    readonly isSubmittingSave$ = this.select((state) => state.isSubmittingSave)

    public readonly setIsSubmittingSave = this.updater((state, save: boolean) => {
        return { ...state, isSubmittingSave: save }
    })

    public readonly setNewsData = this.updater(
        (state, data: { [key: string]: any }) => {
            return {
                ...state,
                newsData: {
                    ...state.newsData,
                    ...data,
                },
            }
        }
    )

    public readonly setDeleteFiles = this.updater(
        (state, files: Observable<PhotoOperation>[]) => {
            return { ...state, deleteFiles: files }
        }
    )

    public readonly setUploadFiles = this.updater(
        (state, files: Observable<PhotoOperation>[]) => {
            return { ...state, uploadFiles: files }
        }
    )

    public readonly resetFiles = this.updater((state) => {
        return { ...state, deleteFiles: [], uploadFiles: [] }
    })
} 