<ng-container *ngIf="newsData$ | async as newsData; else loading" >
  <mat-card
    class="masjid-news-details-card"
    *transloco="let t; read: 'newsData'"
  >
    <img
      matCardImage
      [src]="newsData.imageUrl"
      [alt]="'Image for ' + newsData.title"
    />
    <mat-card-header>
      <mat-card-title>
        <h1>{{ newsData.title }}</h1>
      </mat-card-title>
      <mat-card-subtitle>
        {{ t('publishDate') }}
        {{ newsData.created_date?.toLocaleString() }}
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <p>{{ newsData.description }}</p>
      </div>
      <!-- Video Section -->
      <div *ngIf="newsData.video_id" class="video-container">
        <vg-player>
          <vg-overlay-play [attr.aria-label]="'news.videoPlayer.playVideo' | transloco"></vg-overlay-play>
          <vg-buffering></vg-buffering>
      
          <vg-scrub-bar>
              <vg-scrub-bar-current-time [attr.aria-label]="'news.videoPlayer.currentTime' | transloco"></vg-scrub-bar-current-time>
              <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
          </vg-scrub-bar>
      
          <vg-controls [attr.aria-label]="'news.videoPlayer.controls' | transloco">
              <vg-play-pause [attr.aria-label]="'news.videoPlayer.playPause' | transloco"></vg-play-pause>
              <vg-playback-button [attr.aria-label]="'news.videoPlayer.playbackSpeed' | transloco"></vg-playback-button>
      
              <vg-time-display vgProperty="current" vgFormat="mm:ss" [attr.aria-label]="'news.videoPlayer.currentTime' | transloco"></vg-time-display>
      
              <vg-scrub-bar style="pointer-events: none;" [attr.aria-label]="'news.videoPlayer.seekVideo' | transloco"></vg-scrub-bar>
      
              <vg-time-display vgProperty="left" vgFormat="mm:ss" [attr.aria-label]="'news.videoPlayer.totalDuration' | transloco"></vg-time-display>
              <vg-time-display vgProperty="total" vgFormat="mm:ss" [attr.aria-label]="'news.videoPlayer.totalDuration' | transloco"></vg-time-display>
      
              <vg-track-selector [attr.aria-label]="'news.videoPlayer.selectTrack' | transloco"></vg-track-selector>
              <vg-mute [attr.aria-label]="'news.videoPlayer.mute' | transloco"></vg-mute>
              <vg-volume [attr.aria-label]="'news.videoPlayer.volume' | transloco"></vg-volume>
      
              <vg-fullscreen [attr.aria-label]="'news.videoPlayer.toggleFullscreen' | transloco"></vg-fullscreen>
          </vg-controls>
      
          <video 
            [vgMedia]="$any(media)" 
            #media 
            id="newsVideo" 
            preload="auto" 
            crossorigin
            (error)="onVideoError($event)"
            [attr.aria-label]="'news.videoPlayer.playVideo' | transloco">
              <source [src]="newsData.videoUrl" type="video/mp4">
              <p>{{ 'news.videoPlayer.unsupportedBrowser' | transloco }}</p>
          </video>
        </vg-player>
      </div>
    </mat-card-content>
  </mat-card>
</ng-container>

<ng-template #loading>
  <p>{{ 'news.manage.loading.news' | transloco }}</p>
</ng-template>
