import { CommonModule } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { MatCardModule } from '@angular/material/card'
import { ActivatedRoute } from '@angular/router'
import { TranslocoModule, TranslocoService } from '@ngneat/transloco'
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgStreamingModule } from '@videogular/ngx-videogular/streaming';
import {
    Observable,
    catchError,
    map,
    of,
    switchMap,
    tap,
    throwError,
} from 'rxjs'
import { NewsInterface } from 'src/app/news/interfaces/news.interface'
import { NewsStore } from 'src/app/news/store/states/news.state'
import { LogType } from 'src/app/shared/interfaces/log.interface'
import { LogService } from 'src/app/shared/services/log.service'
import { NewsService } from 'src/app/shared/services/news.service'
import Utils from 'src/app/shared/services/utils'
import { FileStorageService } from 'src/app/shared/services/file-storage.service'
import { environment } from 'src/environments/environment'

@Component({
    selector: 'app-news-details',
    templateUrl: './news-details.component.html',
    styleUrl: './news-details.component.scss',
    standalone: true,
    imports: [
        CommonModule, 
        MatCardModule, 
        TranslocoModule,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule,
        VgStreamingModule
    ],
})
export class NewsDetailsComponent implements OnInit {
    newsData$: Observable<NewsInterface | null>
    videoQualities = [
        {
            qualityIndex: 720,
            width: 1280,
            height: 720,
            bitrate: 2000000,
            mediaType: "video/mp4",
            label: '720p HD'
        },
        {
            qualityIndex: 480,
            width: 854,
            height: 480,
            bitrate: 1000000,
            mediaType: "video/mp4",
            label: '480p'
        },
        {
            qualityIndex: 360,
            width: 640,
            height: 360,
            bitrate: 500000,
            mediaType: "video/mp4",
            label: '360p'
        }
    ];

    constructor(
        private _newsStore: NewsStore,
        private _activatedRoute: ActivatedRoute,
        private _newsService: NewsService,
        private _logService: LogService,
        private _fileService: FileStorageService,
        private _transloco: TranslocoService
    ) {
        this.newsData$ = of(null);
    }

    onVideoError(event: Event): void {
        const error = event instanceof ErrorEvent ? event.error : new Error('Video playback error');
        console.error('Video playback error:', error);
        this._logService.writeLogAsync({
            logType: LogType.Error,
            message: 'Video playback error: ' + Utils.formatException(error)
        });
    }

    ngOnInit(): void {
        const newsId = this._activatedRoute.snapshot.paramMap.get('newsId')
        if (newsId) {
            this.newsData$ = this.findNewsDataById(newsId).pipe(
                map(news => {
                    if (news) {
                        // Create a new object with all the properties from news
                        return {
                            ...news,
                            // Add URLs if media IDs exist
                            imageUrl: news.image_id ? this._fileService.getFilePreviewUrl(
                                environment.news_images_bucket,
                                news.image_id
                            ) : undefined,
                            videoUrl: news.video_id ? this._fileService.getFilePreviewUrl(
                                environment.news_videos_bucket,
                                news.video_id,
                                true
                            ) : undefined
                        } as NewsInterface;
                    }
                    return news;
                }),
                tap(news => {
                    if (news) {
                        console.log('News data loaded:', {
                            id: news.news_id,
                            title: news.title,
                            hasImage: !!news.image_id,
                            hasVideo: !!news.video_id,
                            videoUrl: news.videoUrl || 'No video available'
                        });
                    }
                })
            );
        } else {
            throw new Error('News ID not found');
        }
    }

    findNewsDataById(id: string): Observable<NewsInterface | null> {
        return this.findNewsDataByIdInStore(id).pipe(
            switchMap((news) => {
                if (news !== null) {
                    console.log('masjid news was found in store?', news)
                    return of(news)
                } else {
                    return this._newsService.getMasjidNewsById(id).pipe(
                        catchError((error) => {
                            this._logService.writeLogAsync({
                                logType: LogType.Error,
                                message: Utils.formatException(error),
                            })
                            console.error(
                                'Error fetching news from backend',
                                error
                            )
                            return throwError(
                                () =>
                                    new Error(
                                        'News not found both in store and backend'
                                    )
                            )
                        })
                    )
                }
            })
        )
    }

    findNewsDataByIdInStore(id: string): Observable<NewsInterface | null> {
        return this._newsStore.getNewsList.pipe(
            map((list) => list.find((item) => item.news_id === id) || null)
        )
    }
}
