<mat-card class="news-info-card" *transloco="let t; read: 'newsData'">
  <form [formGroup]="editForm" (ngSubmit)="onSubmit($event)">
    <mat-card-header class="flex-center">
      <mat-card-title>
        <h1 class="text-2xl sm:text-3xl">
          {{ t('editTitle') }}
        </h1>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row" style="margin-top: 1.5rem">
        <div class="col">
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="full-width"
          >
            <mat-label>{{ t('titleLabel') }}</mat-label>
            <input
              matInput
              type="text"
              placeholder="{{ t('titleLabel') }}"
              formControlName="title"
            />
            <mat-error *ngIf="editForm.get('title')?.hasError('required')">
              {{ t('titleLabel') + ' ' + t('errorIsRequired') }}
            </mat-error>
            <mat-error *ngIf="editForm.get('title')?.hasError('minLength')">
              {{ t('titleLabel') + ' ' + t('errorMinLength') + ' 5 ' + t('characters') }}
            </mat-error>
            <mat-error *ngIf="editForm.get('title')?.hasError('maxLength')">
              {{ t('titleLabel') + ' ' + t('errorMaxLength') + ' 100 ' + t('characters') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="full-width"
          >
            <mat-label>{{ t('descriptionLabel') }}</mat-label>
            <textarea
              matInput
              matTextareaAutosize
              rows="4"
              placeholder="{{ t('descriptionLabel') }}"
              formControlName="description"
            ></textarea>
            <mat-error *ngIf="editForm.get('description')?.hasError('required')">
              {{ t('descriptionLabel') + ' ' + t('errorIsRequired') }}
            </mat-error>
            <mat-error *ngIf="editForm.get('description')?.hasError('maxLength')">
              {{ t('descriptionLabel') + ' ' + t('errorMaxLength') + ' 2000 ' + t('characters') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <!-- Current Image Section -->
      <div class="row" *ngIf="currentImage">
        <div class="col">
          <h3>{{ t('currentImage') }}</h3>
          <div class="image-preview">
            <img [src]="currentImage.dataUrl" alt="Current news image">
            <button mat-icon-button color="warn" type="button" (click)="removeCurrentImage()">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <!-- New Image Upload Section -->
      <div class="row">
        <div class="col">
          <app-photo-upload
            [fileLimit]="1"
            [bucketId]="newsBucketId"
            [photoType]="PhotoType.News"
            [isRequired]="false"
            [initFiles]="currentImage ? [currentImage] : []"
            (sendPhotosToParentForm)="onImageSelected($event)"
          ></app-photo-upload>
        </div>
      </div>

      <!-- Current Video Section -->
      <div class="row" *ngIf="currentVideo">
        <div class="col">
          <h3>{{ t('currentVideo') }}</h3>
          <div class="video-preview">
            <video controls>
              <source [src]="currentVideo.dataUrl" type="video/mp4">
            </video>
            <button mat-icon-button color="warn" type="button" (click)="removeCurrentVideo()">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <!-- New Video Upload Section -->
      <div class="row">
        <div class="col">
          <app-video-upload
            [fileLimit]="1"
            [bucketId]="videosBucketId"
            [videoType]="videoType"
            [isRequired]="false"
            [acceptedFormats]="acceptedVideoFormats"
            [maxFileSizeMB]="maxVideoSizeMB"
            (sendVideosToParentForm)="onVideoSelected($event)"
          ></app-video-upload>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions
      class="flex-center full-width"
      style="padding-bottom: 1.5rem"
    >
      <div class="row">
        <div class="col flex-center">
          <button
            type="button"
            mat-raised-button
            color="warn"
            routerLink="/news/list"
          >
            {{ t('cancel') }}
          </button>
        </div>
        <div class="col flex-center">
          <button type="submit" mat-raised-button color="accent" [disabled]="isSubmittingSave$ | async">
            <mat-spinner diameter="20" *ngIf="isSubmittingSave$ | async"></mat-spinner>
            <span *ngIf="!(isSubmittingSave$ | async)">{{ t('save') }}</span>
          </button>
        </div>
      </div>
    </mat-card-actions>
  </form>
</mat-card>
