import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoModule } from '@ngneat/transloco';

interface DialogData {
    title: string;
    message: string;
    confirmText?: string;
    cancelText?: string;
}

@Component({
    selector: 'app-confirm-dialog',
    template: `
        <div class="confirm-dialog">
            <h2 mat-dialog-title>{{ data.title }}</h2>
            <mat-dialog-content>
                <p>{{ data.message }}</p>
            </mat-dialog-content>
            <mat-dialog-actions align="end">
                <button mat-button [mat-dialog-close]="false">
                    {{ data.cancelText || 'common.cancel' | transloco }}
                </button>
                <button mat-button color="warn" [mat-dialog-close]="true">
                    {{ data.confirmText || 'common.confirm' | transloco }}
                </button>
            </mat-dialog-actions>
        </div>
    `,
    styles: [`
        .confirm-dialog {
            padding: 20px;
            min-width: 320px;
        }
        h2 {
            margin: 0;
            font-size: 24px;
            color: #333;
        }
        mat-dialog-content {
            margin: 20px 0;
            p {
                margin: 0;
                font-size: 16px;
                line-height: 1.5;
                color: #666;
            }
        }
        mat-dialog-actions {
            margin-bottom: 0;
            padding: 8px 0;
            button {
                margin-left: 8px;
            }
        }
    `],
    standalone: true,
    imports: [CommonModule, MatDialogModule, MatButtonModule, TranslocoModule],
})
export class ConfirmDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {}
} 