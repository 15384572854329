<div class="news-list">
  <ng-container *ngIf="newsList | async as news; else loading">
    <ng-container *ngIf="news.length > 0; else noNews">
      <app-news-card
        *ngFor="let item of news"
        [newsData]="item"
      ></app-news-card>
    </ng-container>
  </ng-container>

  <!-- No News Template -->
  <ng-template #noNews>
    <div class="no-news">
      <mat-icon>newspaper</mat-icon>
      <p class="no-news-message">{{ 'newsData.noNews' | transloco }}</p>
    </div>
  </ng-template>

  <!-- Loading Template -->
  <ng-template #loading>
    <div class="loading-state">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>
</div>

