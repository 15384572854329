import { CommonModule } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    ValidatorFn,
    Validators,
} from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { ActivatedRoute, Router, RouterModule } from '@angular/router'
import { TranslocoModule, TranslocoService } from '@ngneat/transloco'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { tapResponse } from '@ngrx/operators'
import { AppwriteException } from 'appwrite'
import { Observable, catchError, finalize, map, of, switchMap, tap, throwError, forkJoin } from 'rxjs'
import { NewsInterface } from 'src/app/news/interfaces/news.interface'
import { EditNewsStore } from 'src/app/news/store/states/edit.news.state'
import { PhotoInterface, PhotoType } from 'src/app/shared/interfaces/Image.interface'
import { VideoInterface, VideoType } from 'src/app/shared/interfaces/video.interface'
import { LogType } from 'src/app/shared/interfaces/log.interface'
import { FileStorageService } from 'src/app/shared/services/file-storage.service'
import { LogService } from 'src/app/shared/services/log.service'
import { NewsService } from 'src/app/shared/services/news.service'
import { NotificationService } from 'src/app/shared/services/notification.service'
import { environment } from 'src/environments/environment'
import { PhotoUploadComponent } from 'src/app/shared/components/file-upload/photo-upload/photo-upload.component'
import { VideoUploadComponent } from 'src/app/shared/components/file-upload/video-upload/video-upload.component'
import Utils from 'src/app/shared/services/utils'
import { PhotoOperation } from 'src/app/masjids/interfaces/masjid.state.interface'
import { NewsStore } from 'src/app/news/store/states/news.state'
import { Store } from '@ngrx/store'
import { LengthValidators } from 'src/app/shared/validators/length.validator'

interface FileOperationResult {
    id: string
    operation: string
    imageType: PhotoType | VideoType
}

@UntilDestroy()
@Component({
    selector: 'app-edit-news',
    templateUrl: './edit-news.component.html',
    styleUrl: './edit-news.component.scss',
    standalone: true,
    imports: [
        CommonModule,
        MatCardModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatProgressSpinnerModule,
        PhotoUploadComponent,
        VideoUploadComponent,
        RouterModule,
        TranslocoModule
    ],
})
export class EditNewsComponent implements OnInit {
    editForm: FormGroup
    newsData: NewsInterface
    errorMessage: string = ''
    successMessage: string = ''
    isSubmittingSave$: Observable<boolean>
    newsBucketId: string = environment.news_images_bucket
    videosBucketId: string = environment.news_videos_bucket
    videoType: VideoType = VideoType.News
    PhotoType = PhotoType // Make PhotoType available to template

    // File upload configurations
    maxImageSizeMB: number = 5
    maxVideoSizeMB: number = 30
    acceptedImageFormats: string = 'image/jpeg, image/png, image/jpg'
    acceptedVideoFormats: string = 'video/mp4, video/webm, video/ogg'

    // Current media
    currentImage: PhotoInterface | null = null
    currentVideo: VideoInterface | null = null

    // New media to upload
    newImage: PhotoInterface | null = null
    newVideo: VideoInterface | null = null

    // Media to remove
    imageToRemove: PhotoInterface | null = null
    videoToRemove: VideoInterface | null = null

    constructor(
        private _logService: LogService,
        private _newsService: NewsService,
        private _fb: FormBuilder,
        private _notification: NotificationService,
        private _activatedRoute: ActivatedRoute,
        private _route: Router,
        private _fileService: FileStorageService,
        private _componentStore: EditNewsStore,
        private _newsStore: NewsStore,
        private _store: Store,
        private _transloco: TranslocoService
    ) {
        this.isSubmittingSave$ = this._componentStore.isSubmittingSave$
    }

    ngOnInit(): void {
        this.editForm = this._fb.group({
            title: ['', [
                Validators.required,
                LengthValidators.nameLength()
            ]],
            description: ['', [
                Validators.required,
                LengthValidators.descriptionLength()
            ]],
            masjid_id: ['', Validators.required],
            news_id: ['', Validators.required],
            image_id: [''],
            video_id: [''],
            created_date: [''],
            updated_date: [''],
        })

        const newsIdFromRoute = this._activatedRoute.snapshot.paramMap.get('newsId')

        if (newsIdFromRoute) {
            this._logService.writeLogAsync({
                logType: LogType.Info,
                message: 'Edit news component started!',
            })
            this._newsService
                .getMasjidNewsById(newsIdFromRoute!!)
                .pipe(
                    tapResponse(
                        (news) => {
                            this.newsData = news
                            return news
                        },
                        (error: any) => {
                            this._notification.notifyFailure(this._transloco.translate('news.errors.masjidIdNotFound'))
                            setTimeout(() => {
                                this._route.navigate(['/pageNotFound'])
                            }, 3000)
                        }
                    ),
                    tap(() => {
                        this.editForm.patchValue({
                            title: this.newsData.title,
                            description: this.newsData.description,
                            masjid_id: this.newsData.masjid_id,
                            news_id: this.newsData.news_id,
                            image_id: this.newsData.image_id,
                            video_id: this.newsData.video_id,
                            created_date: this.newsData.created_date,
                            updated_date: this.newsData.updated_date,
                        })

                        // Load existing image if available
                        if (this.newsData.image_id) {
                            this.currentImage = {
                                id: this.newsData.image_id,
                                bucketId: this.newsBucketId,
                                name: 'news-image',
                                photo: null as any, // Will be populated when needed
                                photoType: PhotoType.News,
                                dataUrl: this._fileService.getFilePreviewUrl(
                                    this.newsBucketId,
                                    this.newsData.image_id
                                ),
                            }
                        }

                        // Load existing video if available
                        if (this.newsData.video_id) {
                            this.currentVideo = {
                                id: this.newsData.video_id,
                                bucketId: this.videosBucketId,
                                name: 'news-video',
                                videoFile: null as any, // Will be populated when needed
                                videoType: VideoType.News,
                                dataUrl: this._fileService.getFilePreviewUrl(
                                    this.videosBucketId,
                                    this.newsData.video_id,
                                    true // Specify that this is a video
                                ),
                            }
                        }

                        console.log('news data:', this.newsData)
                    }),
                    untilDestroyed(this)
                )
                .subscribe()
        } else {
            console.log('news id is not available redirecting to home page.', newsIdFromRoute)
            this._route.navigate(['/pageNotFound'])
        }
    }

    onSubmit(event: Event): void {
        if (this.editForm.valid) {
            this._componentStore.setIsSubmittingSave(true)
            this._componentStore.resetFiles()

            let uploadObservables: Observable<FileOperationResult>[] = []
            let deleteObservables: Observable<FileOperationResult>[] = []

            // Handle image operations
            if (this.imageToRemove) {
                const deleteImageObs = this.deleteImage(this.imageToRemove)
                if (deleteImageObs) deleteObservables.push(deleteImageObs)
            }

            if (this.newImage) {
                const uploadImageObs = this.uploadImage(this.newImage)
                if (uploadImageObs) uploadObservables.push(uploadImageObs)
            }

            // Handle video operations
            if (this.videoToRemove) {
                const deleteVideoObs = this.deleteVideo(this.videoToRemove)
                if (deleteVideoObs) deleteObservables.push(deleteVideoObs)
            }

            if (this.newVideo) {
                const uploadVideoObs = this.uploadVideo(this.newVideo)
                if (uploadVideoObs) uploadObservables.push(uploadVideoObs)
            }

            // Update state with file operations
            this._componentStore.setDeleteFiles(deleteObservables as Observable<PhotoOperation>[])
            this._componentStore.setUploadFiles(uploadObservables as Observable<PhotoOperation>[])

            // Combine all file operations
            const allFileOperations = [...deleteObservables, ...uploadObservables]

            // If there are file operations, wait for them to complete
            const updateOperation$ = allFileOperations.length > 0
                ? forkJoin(allFileOperations).pipe(
                    map((results) => {
                        // Get base form data
                        let data: NewsInterface = this.editForm.value as NewsInterface

                        // Process results to get the latest file IDs
                        results.forEach((result) => {
                            if (result.operation === 'upload') {
                                if (result.imageType === PhotoType.News) {
                                    data.image_id = result.id
                                } else if (result.imageType === VideoType.News) {
                                    data.video_id = result.id
                                }
                            }
                        })

                        // Handle file removals
                        if (this.imageToRemove && !this.newImage) {
                            data.image_id = null
                        }
                        if (this.videoToRemove && !this.newVideo) {
                            data.video_id = null
                        }

                        return data
                    })
                )
                : of(this.editForm.value as NewsInterface)

            // Process update operation
            updateOperation$.pipe(
                tap((data) => {
                    console.log('Updating news with data:', data)
                }),
                switchMap((data) => {
                    this._componentStore.setNewsData(data)
                    return this._newsService.updateNewsData(data)
                }),
                switchMap((success) => {
                    if (success) {
                        // After successful update, fetch the latest news to update the store
                        return this._newsService.getLatestNews().pipe(
                            tap((updatedNewsList) => {
                                // Update the news store with the latest data
                                this._newsStore.setNewsList(updatedNewsList)
                                this._notification.notifySuccess(this._transloco.translate('news.success.updateSuccess'))
                                setTimeout(() => {
                                    this._route.navigateByUrl('/news/list')
                                }, 3000)
                            })
                        )
                    }
                    return throwError(() => new Error('Failed to update news'))
                }),
                catchError((error: Error | AppwriteException) => {
                    this._notification.notifyFailure(this._transloco.translate('news.errors.updateFailed'))
                    console.error(error)
                    return throwError(() => error)
                }),
                finalize(() => {
                    this._componentStore.setIsSubmittingSave(false)
                }),
                untilDestroyed(this)
            ).subscribe()
        } else {
            this.editForm.markAllAsTouched()
        }
    }

    onImageSelected(photos: PhotoInterface[]): void {
        if (photos && photos.length > 0) {
            this.newImage = photos[0]
            this.newImage.id = Utils.generateUniqueId()
            if (this.currentImage) {
                this.imageToRemove = this.currentImage
            }
            this.currentImage = this.newImage
        }
    }

    onVideoSelected(videos: VideoInterface[]): void {
        if (videos && videos.length > 0) {
            this.newVideo = videos[0]
            setTimeout(() => {
                if (this.newVideo) {
                    this.newVideo.id = Utils.generateUniqueId()
                    if (this.currentVideo) {
                        this.videoToRemove = this.currentVideo
                    }
                    this.currentVideo = this.newVideo
                }
            }, 1)
        }
    }

    removeCurrentImage(): void {
        if (this.currentImage) {
            if (this.currentImage === this.newImage) {
                // If removing a newly selected image
                this.newImage = null
            } else {
                // If removing an existing image
                this.imageToRemove = this.currentImage
            }
            this.currentImage = null
            this.editForm.patchValue({ image_id: null })
        }
    }

    removeCurrentVideo(): void {
        if (this.currentVideo) {
            if (this.currentVideo === this.newVideo) {
                // If removing a newly selected video
                this.newVideo = null
            } else {
                // If removing an existing video
                this.videoToRemove = this.currentVideo
            }
            this.currentVideo = null
            this.editForm.patchValue({ video_id: null })
        }
    }

    private deleteImage(image: PhotoInterface): Observable<FileOperationResult> {
        return this._fileService.deleteFile(image.id!!, this.newsBucketId).pipe(
            map(() => ({
                id: image.id!!,
                operation: 'delete',
                imageType: PhotoType.News
            })),
            catchError((error: AppwriteException) => {
                // If file not found, log it and continue with the operation
                if (error.code === 404) {
                    this._notification.notifyWarning('The image file was not found on the server, but the operation will continue.')
                    return of({
                        id: image.id!!,
                        operation: 'delete',
                        imageType: PhotoType.News
                    })
                }
                // For other errors, show failure notification and propagate the error
                this._notification.notifyFailure(this._transloco.translate('news.errors.deleteImageFailed'))
                return throwError(() => error)
            })
        )
    }

    private deleteVideo(video: VideoInterface): Observable<FileOperationResult> {
        return this._fileService.deleteFile(video.id!!, this.videosBucketId).pipe(
            map(() => ({
                id: video.id!!,
                operation: 'delete',
                imageType: VideoType.News
            })),
            catchError((error: AppwriteException) => {
                // If file not found, log it and continue with the operation
                if (error.code === 404) {
                    this._notification.notifyWarning('The video file was not found on the server, but the operation will continue.')
                    return of({
                        id: video.id!!,
                        operation: 'delete',
                        imageType: VideoType.News
                    })
                }
                // For other errors, show failure notification and propagate the error
                this._notification.notifyFailure(this._transloco.translate('news.errors.deleteVideoFailed'))
                return throwError(() => error)
            })
        )
    }

    private uploadImage(image: PhotoInterface): Observable<FileOperationResult> {
        return this._fileService
            .uploadFile(image.photo, image.id!!, this.newsBucketId)
            .pipe(
                map((result) => {
                    console.log(`Image uploaded: ${result.$id}`)
                    this.editForm.patchValue({ image_id: result.$id })
                    return {
                        operation: 'upload',
                        imageType: PhotoType.News,
                        id: result.$id,
                    }
                }),
                catchError((error: any) => {
                    this._notification.notifyFailure(this._transloco.translate('news.errors.uploadImageFailed'))
                    return throwError(() => error)
                })
            )
    }

    private uploadVideo(video: VideoInterface): Observable<FileOperationResult> {
        return this._fileService
            .uploadFile(video.videoFile, video.id!!, this.videosBucketId)
            .pipe(
                map((result) => {
                    console.log(`Video uploaded: ${result.$id}`)
                    this.editForm.patchValue({ video_id: result.$id })
                    return {
                        operation: 'upload',
                        imageType: VideoType.News,
                        id: result.$id,
                    }
                }),
                catchError((error: any) => {
                    this._notification.notifyFailure(this._transloco.translate('news.errors.uploadVideoFailed'))
                    return throwError(() => error)
                })
            )
    }
}
