<mat-card class="news-info-card" *transloco="let t; read: 'newsData'">
  <form [formGroup]="createForm" (ngSubmit)="onSubmit($event)">
    <mat-card-header class="flex-center">
      <mat-card-title>
        <h1 class="text-2xl sm:text-3xl">
          {{ t('title') }}
        </h1>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>

      <div class="row" style="margin-top: 1.5rem">
        <div class="col">
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="full-width"
          >
            <mat-label>{{ t('titleLabel') }}</mat-label>
            <input
              matInput
              type="text"
              placeholder="{{ t('titleLabel') }}"
              formControlName="title"
            />
            <mat-error *ngIf="createForm.get('title')?.hasError('required')">
              {{ t('titleLabel') + ' ' + t('errorIsRequired') }}
            </mat-error>
            <mat-error *ngIf="createForm.get('title')?.hasError('minLength')">
              {{ t('titleLabel') + ' ' + t('errorMinLength') + ' 5 ' + t('characters') }}
            </mat-error>
            <mat-error *ngIf="createForm.get('title')?.hasError('maxLength')">
              {{ t('titleLabel') + ' ' + t('errorMaxLength') + ' 100 ' + t('characters') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="full-width"
          >
            <mat-label>{{ t('descriptionLabel') }}</mat-label>
            <textarea
              matInput
              matTextareaAutosize
              placeholder="{{ t('descriptionLabel') }}"
              formControlName="description"
              rows="4"
            ></textarea>
            <mat-error *ngIf="createForm.get('description')?.hasError('required')">
              {{ t('descriptionLabel') + ' ' + t('errorIsRequired') }}
            </mat-error>
            <mat-error *ngIf="createForm.get('description')?.hasError('maxLength')">
              {{ t('descriptionLabel') + ' ' + t('errorMaxLength') + ' 2000 ' + t('characters') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <app-video-upload
        [bucketId]="bucketVideoId"
        [fileLimit]="1"
        [videoType]="videoType"
        [isRequired]="false"
        title="Upload a photo"
        (sendVideosToParentForm)="onVideoSelected($event)"
      ></app-video-upload>

      <app-photo-upload
        [bucketId]="bucketPhotoId"
        [fileLimit]="1"
        [photoType]="photoType"
        [isRequired]="false"
        (sendPhotosToParentForm)="onPhotoSelected($event)"
      ></app-photo-upload>
    </mat-card-content>
    <mat-card-actions
      class="flex-center full-width"
      style="padding-bottom: 1.5rem"
    >
      <div class="row">
        <div class="col flex-center">
          <button
            type="button"
            mat-raised-button
            color="warn"
            routerLink="/news/list"
          >
            {{ t('cancel') }}
          </button>
        </div>
        <div class="col flex-center">
          <button type="submit" mat-raised-button color="accent">
            {{ t('save') }}
          </button>
        </div>
      </div>
    </mat-card-actions>
  </form>
</mat-card>
