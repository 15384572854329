import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { NewsInterface } from 'src/app/news/interfaces/news.interface'
import {
    PhotoInterface,
    PhotoType,
} from 'src/app/shared/interfaces/Image.interface'
import {
    VideoInterface,
    VideoType,
} from 'src/app/shared/interfaces/video.interface'
import { NewsService } from 'src/app/shared/services/news.service'
import { NotificationService } from 'src/app/shared/services/notification.service'
import { environment } from 'src/environments/environment'
import { catchError, forkJoin, Observable, tap, throwError, of, switchMap } from 'rxjs'
import { FileStorageService } from 'src/app/shared/services/file-storage.service'
import { Component, Input, OnInit, NgZone } from '@angular/core'
import {
    FormGroup,
    FormBuilder,
    Validators,
    ReactiveFormsModule,
} from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { tapResponse } from '@ngrx/operators'
import { CommonModule } from '@angular/common'
import {
    MatError,
    MatFormFieldModule,
    MatLabel,
} from '@angular/material/form-field'
import { MatCardModule } from '@angular/material/card'
import { PhotoUploadComponent } from 'src/app/shared/components/file-upload/photo-upload/photo-upload.component'
import { VideoUploadComponent } from 'src/app/shared/components/file-upload/video-upload/video-upload.component'
import { Models } from 'appwrite'
import { TranslocoModule } from '@ngneat/transloco'
import { MatInputModule } from '@angular/material/input'
import { MatButtonModule } from '@angular/material/button'
import Utils from 'src/app/shared/services/utils'
import { LengthValidators } from 'src/app/shared/validators/length.validator'

@UntilDestroy()
@Component({
    selector: 'app-create-news',
    templateUrl: './create-news.component.html',
    styleUrls: ['./create-news.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatError,
        MatLabel,
        MatCardModule,
        PhotoUploadComponent,
        VideoUploadComponent,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        TranslocoModule,
    ],
})
export class CreateNewsComponent implements OnInit {
    bucketPhotoId: string = environment.news_images_bucket
    bucketVideoId: string = environment.news_videos_bucket
    previewImageUrl: string | null = null
    previewVideoUrl: string | null = null
    createForm: FormGroup
    errorMessage: string = ''
    successMessage: string = ''
    returnUrl: string = '/news/manage'

    selectedPhoto: PhotoInterface | null = null
    selectedVideo: VideoInterface | null = null
    videoType: VideoType = VideoType.News
    photoType: PhotoType = PhotoType.News
    @Input() masjidId: string

    constructor(
        private _newsService: NewsService,
        private _fb: FormBuilder,
        private _notification: NotificationService,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _storageService: FileStorageService,
        private _ngZone: NgZone
    ) {}

    ngOnInit(): void {
        // Get return URL from route parameters or previous navigation
        this.returnUrl = this._activatedRoute.snapshot.queryParams['returnUrl'] || 
                        this._router.getCurrentNavigation()?.previousNavigation?.finalUrl?.toString() || 
                        '/news/manage';

        const masjidIdFromRoute =
            this._activatedRoute.snapshot.paramMap.get('masjidId')
        this.masjidId = masjidIdFromRoute || ''
        
        if (!this.masjidId) {
            this._notification.notifyFailure('news.create.errors.noMasjidId')
            this._router.navigate([this.returnUrl])
            return
        }

        this._notification.loading('news.create.loading.initForm')
        
        this.createForm = this._fb.group({
            title: ['', [
                Validators.required,
                LengthValidators.nameLength()
            ]],
            description: ['', [
                Validators.required,
                LengthValidators.descriptionLength()
            ]],
            masjid_id: [this.masjidId, Validators.required],
            news_id: [Utils.generateUniqueId(), Validators.required],
            image_id: [''],
            video_id: [''],
        })
        console.log('news form info:', this.createForm.value)
        this._notification.removeLoading()
    }

    // Capture selected video
    onVideoSelected(videos: VideoInterface[]): void {
        if (videos.length > 0) {
            this._notification.loading('news.create.loading.processingVideo')
            this.selectedVideo = videos[0]
            console.log('Selected Video:', this.selectedVideo)
            this._notification.removeLoading()
        } else {
            this.selectedVideo = null
        }
    }

    // Capture selected photo
    onPhotoSelected(photos: PhotoInterface[]): void {
        if (photos.length > 0) {
            this._notification.loading('news.create.loading.processingPhoto')
            this.selectedPhoto = photos[0]
            console.log('Selected Photo:', this.selectedPhoto)
            this._notification.removeLoading()
        } else {
            this.selectedPhoto = null
        }
    }

    private uploadNewsPhoto(photo: PhotoInterface): Observable<Models.File> {
        this._notification.loading('news.create.loading.uploadingPhoto')
        return this._storageService
            .uploadFile(photo.photo, photo.id!, photo.bucketId)
            .pipe(
                tapResponse(
                    (photoId) => {
                        console.log('Image uploaded successfully', photoId)
                        this._notification.notifySuccess('news.create.success.photoUploaded')
                        this._notification.removeLoading()
                    },
                    (error) => {
                        console.error('Image upload failed', error)
                        this._notification.notifyFailure('news.create.errors.photoUploadFailed')
                        this._notification.removeLoading()
                        throw error; // Re-throw to trigger error handling
                    }
                ),
                untilDestroyed(this)
            )
    }

    private uploadNewsVideo(video: VideoInterface): Observable<Models.File> {
        this._notification.loading('news.create.loading.uploadingVideo')
        return this._storageService
            .uploadFile(video.videoFile!, video.id!, video.bucketId)
            .pipe(
                tapResponse(
                    (videoId) => {
                        console.log('Video uploaded successfully', videoId)
                        this._notification.notifySuccess('news.create.success.videoUploaded')
                        this._notification.removeLoading()
                    },
                    (error) => {
                        console.error('Video upload failed', error)
                        this._notification.notifyFailure('news.create.errors.videoUploadFailed')
                        this._notification.removeLoading()
                        throw error; // Re-throw to trigger error handling
                    }
                ),
                untilDestroyed(this)
            )
    }

    private deleteUploadedFile(fileId: string, bucketId: string): Observable<void> {
        return this._storageService.deleteFile(fileId, bucketId).pipe(
            catchError(error => {
                console.error('Failed to delete file during rollback:', error);
                return of(void 0); // Continue with rollback even if delete fails
            })
        );
    }

    onSubmit(event: Event): void {
        console.info('form submit data:', this.createForm.value)
        if (this.createForm.invalid) {
            this.createForm.markAllAsTouched()
            this._notification.notifyFailure('news.create.errors.invalidForm')
            return
        }
        
        this._notification.loading('news.create.loading.savingNews')
        const formData: NewsInterface = this.createForm.value as NewsInterface

        // Start with video upload if exists
        let uploadSequence$: Observable<Models.File | null> = of(null);
        
        this._ngZone.runOutsideAngular(() => {
            if (this.selectedVideo) {
                uploadSequence$ = this.uploadNewsVideo(this.selectedVideo).pipe(
                    tap(videoResponse => {
                        this._ngZone.run(() => {
                            formData.video_id = videoResponse.$id;
                        });
                    })
                );
            }

            // Chain photo upload after video (if exists)
            if (this.selectedPhoto) {
                uploadSequence$ = uploadSequence$.pipe(
                    switchMap((videoResponse) => this.selectedPhoto ? this.uploadNewsPhoto(this.selectedPhoto) : of(null)),
                    tap(photoResponse => {
                        this._ngZone.run(() => {
                            if (photoResponse) {
                                formData.image_id = photoResponse.$id;
                            }
                        });
                    })
                );
            }

            // Finally save the news document
            uploadSequence$.pipe(
                switchMap(() => this.saveNewsData(formData)),
                catchError(error => {
                    // Rollback on error
                    const rollbackTasks: Observable<void>[] = [];
                    
                    if (formData.video_id) {
                        rollbackTasks.push(this.deleteUploadedFile(formData.video_id, this.bucketVideoId));
                    }
                    if (formData.image_id) {
                        rollbackTasks.push(this.deleteUploadedFile(formData.image_id, this.bucketPhotoId));
                    }

                    return forkJoin(rollbackTasks).pipe(
                        tap(() => {
                            this._ngZone.run(() => {
                                this._notification.notifyFailure('news.create.errors.saveFailed');
                                this._notification.removeLoading();
                            });
                        }),
                        switchMap(() => throwError(() => error))
                    );
                }),
                untilDestroyed(this)
            ).subscribe({
                next: () => {
                    this._ngZone.run(() => {
                        this._notification.notifySuccess('news.create.success.newsCreated');
                        this._notification.removeLoading();
                        this._router.navigate([this.returnUrl]);
                    });
                },
                error: (error) => {
                    this._ngZone.run(() => {
                        console.error('Error in news creation process:', error);
                        this._notification.removeLoading();
                    });
                }
            });
        });
    }

    private saveNewsData(formData: NewsInterface): Observable<any> {
        this._notification.loading('news.create.loading.savingNewsData')
        return this._newsService.createMasjidNews(formData).pipe(
            catchError(error => {
                console.error('Error creating news:', error);
                this._notification.notifyFailure('news.create.errors.saveFailed');
                this._notification.removeLoading();
                throw error; // Re-throw to trigger rollback
            })
        );
    }

    onCancel(): void {
        this._router.navigate([this.returnUrl])
    }
}
