import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { NewsInterface } from '../../interfaces/news.interface'
import { AppwriteException } from 'appwrite'

export const NewsActions = createActionGroup({
    source: 'News',
    events: {
        // Load News List
        'Load Masjids News': emptyProps(),
        'Load Masjids News Success': props<{ newsList: NewsInterface[] }>(),
        'Load Masjids News Failure': props<{ error: Error | AppwriteException }>(),

        // View News Details
        'View News Details And Navigate': props<{ news_id: string }>(),

        // Error Handling
        'Set Error': props<{ error: string }>(),
        'Clear Error': emptyProps(),
        'Handle API Error': props<{ error: Error | AppwriteException }>(),
        'Show Error Notification': props<{ message: string }>(),
    },
})

export const {
    loadMasjidsNews,
    loadMasjidsNewsSuccess,
    loadMasjidsNewsFailure,
    viewNewsDetailsAndNavigate,
    setError,
    clearError,
    handleAPIError,
    showErrorNotification,
} = NewsActions
